<template>
  <div
    class="
      widgetContainer
      header-white
      widgetContainer--scrollable
      checkDepositAction
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
        <p class="cancel" @click="cancel">
          <i class="el-icon-close" />
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="listWithActionsv2">
        <ul>
          <li @click="goToCheckDeposit">
            <div class="details">
              <p class="label">
                {{ $t('RCD_depositCheck') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
          <li @click="goToCheckDepositStatus">
            <div class="details">
              <p class="label">
                {{ $t('RCD_depositedCheck_status') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToCheckDeposit() {
      this.drawerPush('CheckDeposit-contacts');
    },
    goToCheckDepositStatus() {
      this.drawerPush('CheckDeposit-status');
    },
    cancel() {
      this.drawerClose();
    }
  }
};
</script>
